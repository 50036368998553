.SearchBar {
  display: flex;
  margin-top: 20px;
  height: 96px;
}

.Studies {
  font: Bold 32px/20px Noto Sans;
  padding-top: 10px;
  color: #3e3e3e;
  width: 254px;
}

.Controls {
  flex-grow: 1;
  padding-top: 48px;
  width: 24px;
  white-space: nowrap;
}

.Column {
  display: inline-block;
  width: 200px;
  padding-left: 24px;
  color: #3e3e3e;
  font: Bold 14px Noto Sans;
}

.ClickArea {
  cursor: pointer;
  display: inline-block;
}

.Input {
  padding-top: 5px;
  width: 224px;
}

.Input input {
  height: 32px;
  font: 16px Noto Sans;
  width: 224px;
  color: #3e3e3e;
  border: 1px solid #747474;
  border-radius: 4px;
  background: #f9f9f9 0% 0% no-repeat padding-box;
  padding-left: 8px;
  padding-right: 40px;
}

::placeholder {
  color: #b4b1b1;
}
