.Notice {
  border-top: #dbdbdb solid 3px;
  display: flex;
  margin: 0px;
  padding-top: 24px;
}

.Trial {
  flex-grow: 1;
}
