body {
  margin: 0;
  font-family: 'Noto Sans', 'Regular', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: 'Noto Sans', source-code-pro, Menlo, Monaco, Consolas,
    'Courier New', monospace;
}

.page-header-wrapper h1.MuiTypography-root {
  margin-left: 16px;
}

.page-footer-wrapper a.MuiTypography-root.MuiLink-root {
  margin-right: 11px;
}
