.Placeholder {
  width: 230px;
  height: 70px;
  line-height: 70px;
  padding-left: 12px;
  margin-bottom: 24px;

  background-color: #ededed;
  border-radius: 6px;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.Logo {
  max-width: 230px;
  max-height: 140px;

  margin-bottom: 24px;
}
