.SponsorReport {
  display: flex;
  margin: 0;
  padding: 0px;
  border-top: #dbdbdb solid 3px;
}

.LogoColumn {
  width: 254px;
  padding: 24px 10px 0 0;
  font-size: 16px;
  font-weight: 500;
}

.Trials {
  flex-grow: 1;
}
