.Trial {
  display: flex;
  flex-direction: row;
  list-style: none;
  height: 70px;
  border-bottom: #dbdbdb solid 1px;
  transition: background-color 250ms;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.Trial:last-of-type {
  border-bottom: none;
}

.Trial:hover,
.Trial:active {
  background-color: #c0ddf5;
  cursor: pointer;
}

.StudyName {
  width: 200px;
  padding-left: 24px;
  color: #057cb1;
  font: Bold 14px Noto Sans;
  line-height: 70px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.TrialName {
  flex-grow: 1;
  padding-left: 24px;
  color: #3e3e3e;
  font: 14px Noto Sans;
  line-height: 70px;
}
